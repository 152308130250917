<template>
  <section>
      <sm_header title='酒店损失' :smHleftEvent='closeWebview'>
        <template v-slot:smHright v-if="![0,10].includes(orderList.status)">
          <p @click.stop="goDemandList(orderList,'settle')">需求列表</p>
        </template>
      </sm_header>
    <div class="loadmore_container" ref="wrapper" :style="{ height: wrapperHeight + 'px' }">
      <i-scrollBox class="opOrderListScroll" @on-top="scrollTop" :loadType="loadType" @on-bottom="scrollBottom">
        <template v-slot:scrollBox>
          <lost-item v-for="(item, i) in orderList.itsSupplierExpenses" :key="i" :taskId="orderList.taskId"
          :item="item" :status="orderList.taskStatus" :proposalId="proposalId" :orderList="orderList" @updateList="paingEOrder"></lost-item>
          <NoData v-if="!orderList&&loadType==''&&!isFirst"/>
        </template>
      </i-scrollBox>
      <div class="priceInfo">
        <p class="priceTitle">报价/结算信息</p>
        <p class="priceTxt">
          <span>酒店总报价</span>
          <span class="priceColor">{{(orderList.itsProposalTotalAmount.quoteAmount || 0) | currencyFormatter}}</span>
        </p>
        <p class="priceTxt">
          <span>酒店总结算</span>
          <span class="priceColor">{{(orderList.itsProposalTotalAmount.settleAmount || 0) | currencyFormatter}}</span>
        </p>
      </div>
    </div>
    <div class="footerBtnListBox">
      <div class="largeBtn2" @click.stop="goProjectProcess()">查看进程</div>
    </div>
  </section>
</template>
<script>
import iScrollBox from "@/components/iScrollBox/iScrollBox";
import generalBridge from "@/plugin/generalBridge.js";
import lostItem from "@/view/loss/components/lostItem";
export default {
  name: 'supplierList',
  components: {
    iScrollBox,
    lostItem
  },
  data () {
    return {
      orderList: {
        itsProposalTotalAmount: {
          quoteAmount: 0
        }
      },
      fromPage: false,
      isFirst: true,
      loadType: 'none',
      proposalId: '',
      tenant: '',
      supplierImg: require('../../assets/img/detailsImg.png'),
      eventData: {},
      wrapperHeight: 0,
    }
  },
  mounted() {
    this.wrapperHeight = (document.documentElement.clientHeight || window.innerHeight) - this.$refs.wrapper.getBoundingClientRect().top;
  },
  computed: {
  },
  created() {
    this.eventData = this.$SmartStorage.get("eventData");
    this.tenant = this.$cookie.get('tenant');
    if (this.eventData) {
      this.proposalId = this.eventData.proposalId;
    } else {
      this.proposalId = this.$SmartStorage.get("proposalId");
    }
    this.$SmartStorage.remove('taskId')
    this.$SmartStorage.remove('taskStatus')
    this.$SmartStorage.remove('supplierId')
    this.paingEOrder()
    this.andriodBack()
  },
  methods: {
    async paingEOrder (clear) {
      let params = {
        ProposalId: this.proposalId,
        "NodeCode": 'ConfirmVenueLoss',
        "supplierType": 'V2'
      }
      let res = await this.$service.GetConfirmTask(params)
      this.isFirst = false
      if (res && res.content) {
        if (clear == true) {
          this.orderList = {
            itsProposalTotalAmount: {
              quoteAmount: 0
            }
          };
        }
        this.orderList = res.content
        this.loadType = '';
      }
    },
    scrollTop() {
      this.loadType = 'top';
      this.paingEOrder(true)
    },
    scrollBottom() {
      this.loadType = '';
    },
    // 安卓返回键
    andriodBack() {
      this.setRouteListeners('requireListGoBack'); // 保存当前路由返回事件记录
      this.$root.$eventHub.$on("requireListGoBack", () => {
        this.closeWebview();
        this.$root.$eventHub.$off("requireListGoBack");
      });
    },
    // 返回按钮
    closeWebview() {
      generalBridge.propy.call(this)
    },
    goSettleDetail (list) {
      if (list.extData) {
        list.extData = JSON.parse(list.extData)
      }
      this.$SmartStorage.set("lossItem", list);
      this.$SmartStorage.set('supplierId', list.supplierId)
      this.$SmartStorage.set('taskId', list.taskId)
      this.$router.push({ path: "/confirmloss", query: { taskId: this.orderList.taskId} });
    },
    goDemandList () {
      // 去往需求列表
      this.$router.push({ path: "/", query: { fromPage: "lostList" } });
    },
    goProjectProcess(){
      this.$router.push({ path: "/projectProcess", query: { fromPage: 'lostList' }});
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../cost/costList.scss";
.icon {
  width: .2rem;
  height: .2rem;
}
.largeBtn2 {
  border: 1px solid  var(--themeColor);
  color: #FFFFFF;
  background-color:  var(--themeColor);
}
</style>
<style lang="scss">
.opOrderListScroll {
  .scrollSkeleton {
    padding: .15rem .25rem;
    width: auto;
  }
}
</style>
