<template>
  <div class="lostItem" @click="goSettleDetail(item)">
    <div class="demandInfo">
      <img :src="item.itsFiles.length ? item.itsFiles[0].fileUrl : supplierImg"/>
      <div class="venueInfo">
        <div class="venueTitleBox">
          <p class="venueTitle">{{item.supplierName}}</p>
          <span class="tagSign" :style="{color: getColor(item)}"> {{item.statusTxt}}</span>
        </div>
        <p class="venueType">
          <span v-if="orderList.isSelectedVenue">酒店会场</span>
          <span v-if="orderList.isSelectedMeal">酒店用餐</span>
          <span v-if="orderList.isSelectedRoom">酒店住宿</span>
          <span v-if="orderList.isSelectedOther">酒店其他</span>
        </p>
      </div>
    </div>
    <div class="peopleInfo" v-if="!item.isOffline">
      <div class="supplierContact" >
        <span>联系人</span>
        <span class="peopleColor">{{item.contactName}}</span>
      </div>
      <div class="supplierContact" @click.stop="callMobile">
        <a :href="'tel:'+ item.contactMobile">
          <span>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icondianhua"></use>
            </svg>
          </span>
          <span class="peopleColor">{{item.contactMobile}}</span>
        </a>
      </div>
    </div>
    <div class="priceInfo">
      <div class="supplierPrice">
        <span>报价</span>
        <span class="priceColor">{{(item.quoteAmount || 0) | currencyFormatter}}</span>
      </div>
      <div class="supplierPrice">
        <span>损失</span>
        <span class="priceColor">{{(item.settleAmount || 0) | currencyFormatter}}</span>
      </div>
    </div>
    <div class="costListBtnBox" v-if="item.status > 50 && !['pfizer'].includes(tenant)">
      <div class="costListBtn">
        <span @click.stop="goUpdatFile()">补传文件</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'lostItem',
  props: {
    item: {
      type:Object,
      default(){
        return {
          itsFiles: []
        }
      }
    },
    orderList: {
      type:Object,
      default(){
        return {}
      }
    },
    status: {
      type: Number,
      default: 0
    },
    proposalId: {
      type: String,
      default: ''
    },
    taskId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      supplierImg: require('../../../assets/img/detailsImg.png'),
      tenant: this.$cookie.get('tenant')
    }
  },
  methods: {
    // 取消
    falseBtn(){
      let msg = '取消酒店品类需求，将进入损失费流程，会议可能包含损失费，请确认是否继续'
      this.$dialog.confirm({
        message: msg,
      }).then(async () => {
        let params = {
          ProposalId: this.proposalId,
          SupplierId: this.item.supplierId,
          taskId: this.item.taskId,
          LossStatus: 1
        }
        let res =await this.$service.updateLossStatus(params)
        if (res.success) {
          this.$emit('updateList')
        }
      }).catch(() => {
      });
    },
    goUpdatFile () {
      let data = JSON.parse(JSON.stringify(this.item))
      if (data.extData) {
        data.extData = JSON.parse(data.extData)
      }
      this.$SmartStorage.set("selectDemandItem", data);
      this.$router.push({ path: "/supplementFile"});
    },
    goSettleDetail (list) {
      if (list.extData) {
        list.extData = JSON.parse(list.extData)
      }
      this.$SmartStorage.set("lossItem", list);
      this.$SmartStorage.set('supplierId', list.supplierId)
      this.$SmartStorage.set('taskId', list.taskId)
      this.$router.push({ path: "/confirmloss", query: { taskId: this.taskId} });
    },
    callMobile () {
      console.log(this.item.contactMobile)
    },
    getColor (item) {
      let color = '#a67c0b'
      if ([-1].includes(item.status)) {
        color = '#909399'
      } else if ([11,31,37,51,100].includes(item.status)) {
        color = '#4ac672'
      } else if ([12].includes(item.status)) {
        color = '#ff484d'
      }
      return color
    }
  }
}
</script>
<style scoped lang="scss">
.lostItem {
  padding: .1rem;
  background: linear-gradient(-55deg, #FFFFFF, #F8F8F8);
  box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.15);
  border-radius: .04rem;
  position: relative;
  margin-bottom: .1rem;
  .demandInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    img {
      width: .37rem;
      height: .37rem;
      border-radius: .04rem;
    }
    .venueInfo {
      margin-left: .1rem;
      text-align: left;
      width: 100%;
      .venueTitleBox {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .venueTitle {
        font-size: .15rem;
        font-weight: bold;
        color: #13161A;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 2.3rem;
      }
      .venueType{
        span{
          color: var(--themeColor);
          font-size: .12rem;
          margin-right: 0.05rem;
          display: inline-block;
          padding: 0.02rem 0.05rem;
          position: relative;
          border-radius: .04rem;
          overflow: hidden;
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: .1;
            left: 0;
            bottom: 0;
            background-color: var(--themeColor);
          }
        }
      }
    }
  }
  .peopleInfo {
    text-align: left;
    margin-top: .1rem;
    .supplierContact {
      display: inline-block;
      margin-right: .1rem;
      span{
        font-size: .13rem;
        font-weight: 500;
        color: #90939A;
      }
      .peopleColor{
        font-size: .15rem;
        font-weight: 500;
        color: #13161A;
      }
      .icon{
        width: 20px;
        height: 15px;
        color: #3875FE;
      }
    }
  }
  .priceInfo {
    text-align: left;
    .supplierPrice {
      display: inline-block;
      margin-right: .1rem;
      span{
        font-size: .13rem;
        font-weight: 500;
        color: #90939A;
      }
      .priceColor{
        font-size: .15rem;
        font-weight: 500;
        color: #13161A;
      }
    }
  }
  .tagSign {
    color: #FECE30;
    font-size: .13rem;
    font-weight: 100;
  }
  .costListBtnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: .1rem;
    border-top: 1px solid #fff;
    padding-top: .1rem;
    .costListBtn {
      margin: 0 0 0 0.1rem;
      width: 69px;
      height: 31px;
      background: #ffffff00;
      border: 1px solid #DEDEDE;
      border-radius: 4px;
      line-height: 31px;
      &:last-child{
        border: 1px solid var(--themeColor);
        background: var(--themeColor);
        span{
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
